import { createApp } from 'vue';
import { createPinia } from 'pinia';

import App from './App.vue';
import router from './router';
import Popper from 'vue3-popper';

import './assets/styles/style.scss';
import './assets/css/tailwind.css';
import VueAwesomePaginate from 'vue-awesome-paginate';
import 'vue-awesome-paginate/dist/style.css';
import mitt from 'mitt';

import axios from 'axios';
const emitter = mitt();
axios.defaults.headers = {
    'X-CLIENT-ID': 2,
    'X-CLIENT-SECRET': 'yPCbaUpbQLPu9Z8SnzpxsGwqt7avL35FqLRL6Tev',
};

// Environment variables
const app = createApp(App);

app.config.globalProperties.emitter = emitter;
app.use(VueAwesomePaginate);

app.component('PopperComponent', Popper);
app.use(createPinia());
app.use(router);

app.mount('#app');
