<template>
    <div class="qooBox-main">
        <router-view />
    </div>
    <OverlayLoader v-show="isLoading" />
</template>

<script setup>
import OverlayLoader from '@/components/base/overlay/OverlayLoader.vue';

import { computed, watch } from 'vue';
import { useRoute } from 'vue-router'
import { useStore } from '@/store/loading.js';

const store = useStore();

const isLoading = computed(() => {
    return store.isLoading;
});

const route = useRoute()

watch(
    () => route.path,
    () => {
        document.title = 'Modern Farm House'
    }
)
</script>
