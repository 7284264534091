import { createRouter, createWebHashHistory } from 'vue-router';
// import { useCookies } from 'vue3-cookies';

// const { cookies } = useCookies();
function lazyLoad(view) {
    return () => import(`../views/${view}.vue`);
}

// const checkAuth = (to, from, next) => {
//     const isAuthenticated = cookies.get('token');
//     if (to.name === 'home' && isAuthenticated) {
//         next('/project');
//     }
//     if (to.meta.requiresAuth && !isAuthenticated) {
//         next('/');
//     } else {
//         next();
//     }
// };

const router = createRouter({
    history: createWebHashHistory(),
    routes: [
        {
            path: '/',
            name: 'home',
            // beforeEnter: checkAuth,
            meta: {
                title: 'Home Page'
              },
            component: () => import('../views/HomeView.vue'),
        },
    ],
});

export default router;
